<ui-popup-yes-or-no
  [isOpen]="(yesOrNoPopupState$ | async)?.isOpen || false"
  [p-title]="(yesOrNoPopupState$ | async)?.title || ''"
  [message]="(yesOrNoPopupState$ | async)?.message || ''"
  (onNo)="popupsState.emitYesOrNo(false)"
  (onYes)="popupsState.emitYesOrNo(true)"
></ui-popup-yes-or-no>

<ui-popup-ok
  [isOpen]="(okPopupState$ | async)?.isOpen || false"
  [p-title]="(okPopupState$ | async)?.title || ''"
  [message]="(okPopupState$ | async)?.message || ''"
  (onOk)="popupsState.emitOk()"
></ui-popup-ok>