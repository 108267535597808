import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CadastroService } from './cadastro.service';
import { ObterCidadesAtendidasResponse } from "./DTO/response/obterCidadesAtendidasResponse";
import { ObterDadosCadastraisPeloCPFResponse } from "./DTO/response/obterDadosCadastraisPeloCPFResponse";
import { ObterDadosDeEnderecoPeloCepResponse } from "./DTO/response/obterDadosDeEnderecoPeloCEPResponse";
import { ObterProfissoesResponse } from "./DTO/response/obterProfissoesResponse";
import { ObterUfsAtendidasResponse } from "./DTO/response/obterUfsAtendidasResponse";
import { Observable, of } from 'rxjs';
import { ObterDocumentacaoNecessariaParaElegibilidadeResponse } from './DTO/response/obterDocumentacaoNecessariaParaElegibilidadeResponse';
import { EnviarDocumentacaoPayload } from './DTO/request/enviarDocumentacaoPayload';
import { ObterPlanoResponse } from './DTO/response/obterPlanoResponse';
import { RealizarPedidoDeAssociacaoResponse } from './DTO/response/realizarPedidoDeAssociacaoResponse';
import { RealizarPedidoDeAssociacaoPayload } from './DTO/request/enviarPedidoDeAssociacaoPayload';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CadastroAPIService implements CadastroService {
  constructor(
    private http: HttpClient,
  ) {}

  public obterProfissoes(): Observable<ObterProfissoesResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.obterProfissoes;
    const url = host + endpoint;

    return this.http.get<ObterProfissoesResponse>(url);
  }

  public obterUFsAtendidas(): Observable<ObterUfsAtendidasResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.obterEstados;
    const url = host + endpoint;

    return this.http.get<ObterUfsAtendidasResponse>(url);
  }

  public obterCidadesAtendidas(): Observable<ObterCidadesAtendidasResponse> {
    return of([
      {
        id: 1,
        nome: "Rio de Janeiro",
      },
      {
        id: 2,
        nome: "São Paulo",
      },
      {
        id: 3,
        nome: "Espirito Santo",
      },
    ]);
  }

  public obterDadosCadastraisPeloCPF(cpf: string, recaptchaToken: string): Observable<ObterDadosCadastraisPeloCPFResponse | null> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.obterDadosCadastraisPeloCpf;
    const url = host + endpoint;
    let queryParams = new HttpParams();

    queryParams = queryParams.append("cpf", cpf);
    queryParams = queryParams.append("idEntidade", environment.idEntidade);
    queryParams = queryParams.append("recaptchaToken", recaptchaToken);

    return this.http.get(url, { params: queryParams });
  }

  public obterDadosDeEnderecoPeloCEP(cep: string, recaptchaToken: string): Observable<ObterDadosDeEnderecoPeloCepResponse | null> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.obterDadosCadastraisPeloCep;
    const url = host + endpoint;
    let queryParams = new HttpParams();

    queryParams = queryParams.append("cep", cep);
    queryParams = queryParams.append("recaptchaToken", recaptchaToken);

    return this.http.get(url, { params: queryParams });
  }

  public obterDocumentacaoNecessariaParaElegibilidade() {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.documentacao;
    const url = host + endpoint;

    return this.http.get<ObterDocumentacaoNecessariaParaElegibilidadeResponse>(url);
  }

  enviarDocumentacao(payload: EnviarDocumentacaoPayload): Observable<null> {
    return of(null);
  }

  obterPlano(): Observable<ObterPlanoResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.get.obterPlano;
    const url = host + endpoint;

    return this.http.get<ObterPlanoResponse>(url, { params: { idEntidade: environment.idEntidade }});
  }

  realizarPedido(payload: RealizarPedidoDeAssociacaoPayload): Observable<RealizarPedidoDeAssociacaoResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.preCadastro.post.realizarPreCadastro;
    const url = host + endpoint;

    const formData = new FormData();

    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.Cpf', payload.dadosPessoaisDoAssociado.dadosCadastrais.cpf);
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.NomeCompleto', payload.dadosPessoaisDoAssociado.dadosCadastrais.nomeCompleto);
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.DataDeNascimento', payload.dadosPessoaisDoAssociado.dadosCadastrais.dataDeNascimento);
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.Telefone', payload.dadosPessoaisDoAssociado.dadosCadastrais.telefone);
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.Email', payload.dadosPessoaisDoAssociado.dadosCadastrais.email);
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.NomeDaMae', payload.dadosPessoaisDoAssociado.dadosCadastrais.nomeDaMae || '');
    formData.append('DadosPessoaisDoAssociado.DadosCadastrais.Genero', payload.dadosPessoaisDoAssociado.dadosCadastrais.genero || '');

    if(payload.dadosPessoaisDoAssociado.dadosCadastrais.profissaoId)
      formData.append('DadosPessoaisDoAssociado.DadosCadastrais.ProfissaoId', payload.dadosPessoaisDoAssociado.dadosCadastrais.profissaoId.toString());

    formData.append('DadosPessoaisDoAssociado.Endereco.Cep', payload.dadosPessoaisDoAssociado.endereco.cep);
    formData.append('DadosPessoaisDoAssociado.Endereco.Logradouro', payload.dadosPessoaisDoAssociado.endereco.logradouro);
    formData.append('DadosPessoaisDoAssociado.Endereco.Numero', payload.dadosPessoaisDoAssociado.endereco.numero);
    formData.append('DadosPessoaisDoAssociado.Endereco.Complemento', payload.dadosPessoaisDoAssociado.endereco.complemento || '');
    formData.append('DadosPessoaisDoAssociado.Endereco.Bairro', payload.dadosPessoaisDoAssociado.endereco.bairro);
    formData.append('DadosPessoaisDoAssociado.Endereco.Uf', payload.dadosPessoaisDoAssociado.endereco.uf);
    formData.append('DadosPessoaisDoAssociado.Endereco.Cidade', payload.dadosPessoaisDoAssociado.endereco.cidade);

    payload.anexos.forEach(anexo => {
      formData.append('Anexos', anexo.arquivo, anexo.descricao);
    });

    return this.http.post<RealizarPedidoDeAssociacaoResponse>(url, formData);
  }
}
