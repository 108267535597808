import { Injectable } from '@angular/core';
import { DadosAssociadoService } from './dados-associado.service';
import { GetAssociadoResponse } from './DTO/response/getAssociadoResponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DadosAssociadoApiService implements DadosAssociadoService  {
  constructor(
    private http: HttpClient,
  ) {}

  public getAssociado(id: string): Observable<GetAssociadoResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.associado.get.getAssociado;
    const url = host + endpoint + '/' + id;

    return this.http.get<GetAssociadoResponse>(url);
  }
}
