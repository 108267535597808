<section class="footer">
  <div class="container">
    <div class="blocoTitulo">
      <h5 class="titulo">ClubCard</h5>
      <p class="subtitulo">Clubcard Benefícios</p>
    </div>
    <div class="blocoContato">

      <p>Av. Rio Branco, 125 - 16º - Centro - Rio de Janeiro/RJ</p>
      <div></div>
      <p>
        <img src="../../../../assets/icones/footer/icone_whatsapp.svg"/>
        21 96470-1758
        <span>|</span>
        <img src="../../../../assets/icones/footer/icone-email.svg"/>
        cadastro&#64;clubcard.org.br
      </p>
    </div>
    <img src="../../../../assets/logos/logo_associaFacil.svg"/>
  </div>
</section>
