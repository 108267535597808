import { Component } from '@angular/core';
import { LoadingScreenStateService } from '../../states/LoadingScreenState/loading-screen-state.service';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent {
    public isLoading$ = this.loadingScreenService.getLoadingAsObservable().pipe(debounceTime(100));

    constructor(private loadingScreenService: LoadingScreenStateService) { }
}
