import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-popup-yes-or-no',
  templateUrl: './popup-yes-or-no.component.html',
  styleUrls: ['./popup-yes-or-no.component.scss'],
})
export class PopupYesOrNoComponent {
  @Input() isOpen: boolean = false;
  @Input('p-title') title: string = '';
  @Input() message: string = '';
  @Output() onYes = new EventEmitter();
  @Output() onNo = new EventEmitter();

  ngOnInit() {}
}
