import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ui-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnChanges {
  @Input() isOpen: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['isOpen'].currentValue) {
        document.body.style.setProperty('height', '100%');
        document.body.style.setProperty('overflow', 'hidden');
      }
      else {
        document.body.style.setProperty('height', 'initial');
        document.body.style.setProperty('overflow', 'initial');
      }
  }
}
