import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type Item = { nome: string, id: number };
type SelectedItem = { nome: string, id: number } | number;

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => CustomSelectComponent)
  }]
})
export class CustomSelectComponent implements ControlValueAccessor {
  @Input() options: Item[] = [];
  @Output() onSelect = new EventEmitter();
  @Input() useIdAsValue: boolean = false;
  public disabled: boolean = false;
  public selectedOption?: SelectedItem;
  public optionsVisible: boolean = false;
  public displayName: string = 'Selecione um item';

  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor() { }

  toggleOptions() {
    this.onTouched();
    this.optionsVisible = !this.optionsVisible;
  }

  selectOption(option: Item) {
    if(!this.disabled) {
      this.optionsVisible = false;
      this.writeValue(this.useIdAsValue ? option.id : option);
    }
  }

  writeValue(value?: SelectedItem | null): void {
    if(value === undefined || value === null)
      return;

    if(typeof value === 'number') {
      this.displayName = this.options.find(o => o.id === value)!.nome;
    }
    else {
      this.displayName = value.nome;
    }

    this.selectedOption = value;
    this.onChange(value);
    this.onSelect.emit(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
