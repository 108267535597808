import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

class PopupData<ResolveValue> {
  public isOpen: boolean = false;
  public title: string = '';
  public message: string = '';
  public resolver?: (value: ResolveValue) => void;
}

@Injectable({ providedIn: 'root' })
export class PopupsState {
  private yesOrNoPopupState = new BehaviorSubject<PopupData<boolean>>(new PopupData());
  private okPopupState = new BehaviorSubject<PopupData<void>>(new PopupData());

  public openOkPopup(title: string, message: string): Promise<void> {
    return new Promise(resolve => {
      this.okPopupState.next({ isOpen: true, title, message, resolver: resolve });
    });
  }

  public openYesOrNoPopup(title: string, message: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.yesOrNoPopupState.next({ isOpen: true, title, message, resolver: resolve });
    });
  }

  public getOkPopupStateAsObservable() {
    return this.okPopupState.asObservable();
  }

  public getYesOrNoPopupStateAsObservable() {
    return this.yesOrNoPopupState.asObservable();
  }

  public isOkPopupOpen() {
    return this.okPopupState.getValue().isOpen;
  }

  public isYesOrNoPopupOpen() {
    return this.yesOrNoPopupState.getValue().isOpen;
  }

  public emitOk() {
    this.resolveOkPopup();

    this.okPopupState.next({
      ...this.okPopupState.value,
      isOpen: false,
      resolver: undefined,
    });
  }
  
  public emitYesOrNo(value: boolean) {
    this.resolveYesOrNoPopup(value);

    this.yesOrNoPopupState.next({
      ...this.yesOrNoPopupState.value,
      isOpen: false,
      resolver: undefined,
    });
  }

  private resolveOkPopup() {
    if(this.okPopupState.value.resolver)
      this.okPopupState.value.resolver();
  }

  private resolveYesOrNoPopup(value: boolean) {
    if(this.yesOrNoPopupState.value.resolver)
      this.yesOrNoPopupState.value.resolver(value);
  }
}