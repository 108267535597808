import { Observable } from "rxjs";
import { ObterCidadesAtendidasResponse } from "./DTO/response/obterCidadesAtendidasResponse";
import { ObterDadosCadastraisPeloCPFResponse } from "./DTO/response/obterDadosCadastraisPeloCPFResponse";
import { ObterDadosDeEnderecoPeloCepResponse } from "./DTO/response/obterDadosDeEnderecoPeloCEPResponse";
import { ObterProfissoesResponse } from "./DTO/response/obterProfissoesResponse";
import { ObterUfsAtendidasResponse } from "./DTO/response/obterUfsAtendidasResponse";
import { ObterDocumentacaoNecessariaParaElegibilidadeResponse } from "./DTO/response/obterDocumentacaoNecessariaParaElegibilidadeResponse";
import { EnviarDocumentacaoPayload } from "./DTO/request/enviarDocumentacaoPayload";
import { ObterPlanoResponse } from "./DTO/response/obterPlanoResponse";
import { RealizarPedidoDeAssociacaoResponse } from "./DTO/response/realizarPedidoDeAssociacaoResponse";
import { RealizarPedidoDeAssociacaoPayload } from "./DTO/request/enviarPedidoDeAssociacaoPayload";

export abstract class CadastroService {
  abstract obterProfissoes(): Observable<ObterProfissoesResponse>;
  abstract obterUFsAtendidas(): Observable<ObterUfsAtendidasResponse>;
  abstract obterCidadesAtendidas(): Observable<ObterCidadesAtendidasResponse>;
  abstract obterDadosCadastraisPeloCPF(cpf: string, recaptchaToken: string): Observable<ObterDadosCadastraisPeloCPFResponse | null>;
  abstract obterDadosDeEnderecoPeloCEP(cep: string, recaptchaToken: string): Observable<ObterDadosDeEnderecoPeloCepResponse | null>;
  abstract obterDocumentacaoNecessariaParaElegibilidade(): Observable<ObterDocumentacaoNecessariaParaElegibilidadeResponse>;
  abstract enviarDocumentacao(payload: EnviarDocumentacaoPayload): Observable<null>;
  abstract obterPlano(): Observable<ObterPlanoResponse>;
  abstract realizarPedido(payload: RealizarPedidoDeAssociacaoPayload): Observable<RealizarPedidoDeAssociacaoResponse>;
}
