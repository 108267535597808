import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';

const routers: Routes = [
  {
    path: '',
    redirectTo: 'cadastro',
    pathMatch: 'full'
  },
  {
    path: 'cadastro',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/cadastro/cadastro.module').then(m => m.CadastroModule)
      }
    ]
  },
  {
    path: 'pagamento',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/pagamento/pagamento.module').then(m => m.PagamentoModule)
      }
    ]
  },
  {
    path: 'dados-associado',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dados-associado/dados-associado.module').then(m => m.DadosAssociadoModule)
      }
    ]
  },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forRoot(routers, { preloadingStrategy: PreloadAllModules })
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
