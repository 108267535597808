import { Injectable } from "@angular/core";
import { PagamentoService } from "./pagamento.service";
import { Observable } from "rxjs";
import { ObterPedidoPorUidResponse } from "./DTO/response/ObterPedidoPorUidResponse";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PagarComCartaoDeCreditoPayload } from "./DTO/request/PagarComCartaoDeCreditoPayload";
import { ResultadoPagamentoResponse } from "./DTO/response/PagarComCartaoDeCreditoResponse";
import { ObterParcelasResponse } from "./DTO/response/ObterParcelasResponse";
import { ObterPedidoPorUidParams } from "./DTO/request/ObterPedidoPorUidParams";
import { GerarPagamentoPixPayload } from "./DTO/request/GerarPagamentoPixPayload";
import { GerarPagamentoPixResponse } from "./DTO/response/GerarPagamentoPixResponse";
import { StatusPagamentoPixPayload } from "./DTO/request/StatusPagamentoPixPayload";
import { StatusPagamentoPixResponse } from "./DTO/response/StatusPagamentoPixResponse";

@Injectable({
  providedIn: 'root'
})
export class PagamentoAPIService implements PagamentoService {

  constructor(private http: HttpClient) { }

  public pagarComCartaoDeCredito(payload: PagarComCartaoDeCreditoPayload): Observable<ResultadoPagamentoResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.pagamento.post.pagarComCartaoDeCredito;
    const url = host + endpoint;

    return this.http.post<ResultadoPagamentoResponse>(url, payload);
  }

  public obterPedidoPorUid(params: ObterPedidoPorUidParams): Observable<ObterPedidoPorUidResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.pagamento.get.obterPedidoPorUid;
    const url = host + endpoint;

    let queryParams = new HttpParams();
    queryParams = queryParams.append('uid', params.uid);

    return this.http.get<ObterPedidoPorUidResponse>(url, { params: queryParams });
  }

  public obterParcelasDisponiveis(pedidoUid: string): Observable<ObterParcelasResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.pagamento.get.obterParcelasDoPedido;
    const url = host + endpoint;

    let queryParams = new HttpParams();

    queryParams = queryParams.append('pedidoUid', pedidoUid);
    queryParams = queryParams.append('idEntidade', environment.idEntidade);

    return this.http.get<ObterParcelasResponse>(url, { params: queryParams });
  }

  public gerarPagamentoPix(payload: GerarPagamentoPixPayload): Observable<GerarPagamentoPixResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.pagamento.post.gerarPagamentoPix;
    const url = host + endpoint;

    return this.http.post<GerarPagamentoPixResponse>(url, payload);
  }

  public statusPagamentoPix(payload: StatusPagamentoPixPayload): Observable<StatusPagamentoPixResponse> {
    const host = environment.api.associaFacil.host;
    const endpoint = environment.api.associaFacil.endpoints.pagamento.post.statusPagamentoPix;
    const url = host + endpoint;

    return this.http.post<StatusPagamentoPixResponse>(url, payload);
  }
}
