import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { FooterComponent } from './containers/components/footer/footer.component';
import { HeaderComponent } from './containers/components/header/header.component';
import { CadastroService } from './modules/cadastro/service/cadastro.service';
import { CadastroMockService } from './modules/cadastro/service/cadastro-mock.service';
import { environment } from 'src/environments/environment';
import { CadastroAPIService } from './modules/cadastro/service/cadastro-api.service.ts';
import { provideNgxMask } from 'ngx-mask';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { LoadingScreenInterceptor } from './shared/interceptors/loadingScreenInterceptor';
import { PagamentoService } from './modules/pagamento/services/pagamento.service';
import { PagamentoMockService } from './modules/pagamento/services/pagamento-mock.service';
import { PagamentoAPIService } from './modules/pagamento/services/pagamento-api.service';
import { GoogleRecaptchaApiService, GoogleRecaptchaMockService, GoogleRecaptchaService } from './shared/services/google-recaptcha.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { DadosAssociadoService } from './modules/dados-associado/services/dados-associado.service';
import { DadosAssociadoMockService } from './modules/dados-associado/services/dados-associado-mock.service';
import { DadosAssociadoApiService } from './modules/dados-associado/services/dados-associado-api.service';

const servicesIoc = {
  mock: [
    {
      provide: CadastroService,
      useClass: CadastroMockService,
    },
    {
      provide: PagamentoService,
      useClass: PagamentoMockService,
    },
    {
      provide: DadosAssociadoService,
      useClass: DadosAssociadoMockService,
    },
    {
      provide: GoogleRecaptchaService,
      useClass: GoogleRecaptchaMockService,
    },
  ],
  api: [
    {
      provide: CadastroService,
      useClass: CadastroAPIService,
    },
    {
      provide: PagamentoService,
      useClass: PagamentoAPIService,
    },
    {
      provide: DadosAssociadoService,
      useClass: DadosAssociadoApiService,
    },
    {
      provide: GoogleRecaptchaService,
      useClass: GoogleRecaptchaApiService,
    },
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaV3Module,
  ],
  providers: [
    environment.mock ? servicesIoc.mock : servicesIoc.api,
    provideNgxMask(),
    provideHttpClient(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.v3key },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
