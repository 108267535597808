import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSelectComponent } from './ui-toolkit/custom-select/custom-select.component';
import { ProgressoComponent } from './components/progresso/progresso.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { PopupComponent } from './ui-toolkit/popup/popup.component';
import { PopupOkComponent } from './ui-toolkit/popup-ok/popup-ok.component';
import { PopupYesOrNoComponent } from './ui-toolkit/popup-yes-or-no/popup-yes-or-no.component';
import { GlobalPopupsComponent } from './components/global-popups/global-popups.component';

@NgModule({
  declarations: [
    CustomSelectComponent,
    ProgressoComponent,
    LoadingScreenComponent,
    PopupComponent,
    PopupOkComponent,
    PopupYesOrNoComponent,
    GlobalPopupsComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomSelectComponent,
    ProgressoComponent,
    LoadingScreenComponent,
    PopupComponent,
    PopupOkComponent,
    PopupYesOrNoComponent,
    GlobalPopupsComponent,
  ]
})
export class SharedModule { }
