import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-popup-ok',
  templateUrl: './popup-ok.component.html',
  styleUrls: ['./popup-ok.component.scss'],
})
export class PopupOkComponent {
  @Input() isOpen: boolean = false;
  @Input('p-title') title: string = '';
  @Input() message: string = '';
  @Output() onOk = new EventEmitter();

  ngOnInit() {}
}
