import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { PopupsState } from '../../states/PopupState/popups-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-popups',
  templateUrl: './global-popups.component.html',
  styleUrls: ['./global-popups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalPopupsComponent implements OnInit, OnDestroy {
  public yesOrNoPopupState$ = this.popupsState.getYesOrNoPopupStateAsObservable();
  public okPopupState$ = this.popupsState.getOkPopupStateAsObservable();
  public subscription?: Subscription;

  constructor(
    public popupsState: PopupsState,
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
