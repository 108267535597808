import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenStateService {
  private loadingState = new BehaviorSubject<boolean>(false);

  isLoading(): boolean {
    return this.loadingState.value;
  }

  setLoading(value: boolean) {
    this.loadingState.next(value);
  }

  startLoading() {
    this.loadingState.next(true);
  }

  stopLoading() {
    this.loadingState.next(false);
  }

  getLoadingAsObservable() {
    return this.loadingState.asObservable();
  }
}
