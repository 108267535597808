import { Observable } from "rxjs";
import { ObterPedidoPorUidResponse } from "./DTO/response/ObterPedidoPorUidResponse";
import { ResultadoPagamentoResponse } from "./DTO/response/PagarComCartaoDeCreditoResponse";
import { PagarComCartaoDeCreditoPayload } from "./DTO/request/PagarComCartaoDeCreditoPayload";
import { ObterParcelasResponse } from "./DTO/response/ObterParcelasResponse";
import { ObterPedidoPorUidParams } from "./DTO/request/ObterPedidoPorUidParams";
import { GerarPagamentoPixPayload } from "./DTO/request/GerarPagamentoPixPayload";
import { GerarPagamentoPixResponse } from "./DTO/response/GerarPagamentoPixResponse";
import { StatusPagamentoPixResponse } from "./DTO/response/StatusPagamentoPixResponse";
import { StatusPagamentoPixPayload } from "./DTO/request/StatusPagamentoPixPayload";

export abstract class PagamentoService {
  abstract obterPedidoPorUid(params: ObterPedidoPorUidParams): Observable<ObterPedidoPorUidResponse>;
  abstract obterParcelasDisponiveis(pedidoUid: string): Observable<ObterParcelasResponse>;
  abstract pagarComCartaoDeCredito(payload: PagarComCartaoDeCreditoPayload): Observable<ResultadoPagamentoResponse>;
  abstract gerarPagamentoPix(payload: GerarPagamentoPixPayload): Observable<GerarPagamentoPixResponse>;
  abstract statusPagamentoPix(payload: StatusPagamentoPixPayload): Observable<StatusPagamentoPixResponse>;
}
