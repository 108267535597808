import { Injectable } from '@angular/core';
import { CadastroService } from './cadastro.service';
import { ObterCidadesAtendidasResponse } from "./DTO/response/obterCidadesAtendidasResponse";
import { ObterDadosCadastraisPeloCPFResponse } from "./DTO/response/obterDadosCadastraisPeloCPFResponse";
import { ObterDadosDeEnderecoPeloCepResponse } from "./DTO/response/obterDadosDeEnderecoPeloCEPResponse";
import { ObterProfissoesResponse } from "./DTO/response/obterProfissoesResponse";
import { ObterUfsAtendidasResponse } from "./DTO/response/obterUfsAtendidasResponse";
import { Observable, of } from 'rxjs';
import { ObterDocumentacaoNecessariaParaElegibilidadeResponse } from './DTO/response/obterDocumentacaoNecessariaParaElegibilidadeResponse';
import { EnviarDocumentacaoPayload } from './DTO/request/enviarDocumentacaoPayload';
import { ObterPlanoResponse } from './DTO/response/obterPlanoResponse';
import { RealizarPedidoDeAssociacaoResponse } from './DTO/response/realizarPedidoDeAssociacaoResponse';
import { RealizarPedidoDeAssociacaoPayload } from './DTO/request/enviarPedidoDeAssociacaoPayload';

@Injectable({
  providedIn: 'root'
})
export class CadastroMockService implements CadastroService {
  public obterProfissoes(): Observable<ObterProfissoesResponse> {
    return of([
      {
        id: 1,
        nome: "Profissão 1",
      },
      {
        id: 2,
        nome: "Profissão 2",
      },
      {
        id: 3,
        nome: "Profissão com descrição longa 3",
      },
    ]);
  }

  public obterUFsAtendidas(): Observable<ObterUfsAtendidasResponse> {
    return of([
      {
        id: 1,
        nome: "RJ",
      },
      {
        id: 2,
        nome: "SP",
      },
      {
        id: 3,
        nome: "ES",
      },
    ]);
  }

  public obterCidadesAtendidas(): Observable<ObterCidadesAtendidasResponse> {
    return of([
      {
        id: 1,
        nome: "Rio de Janeiro",
      },
      {
        id: 2,
        nome: "São Paulo",
      },
      {
        id: 3,
        nome: "Espirito Santo",
      },
    ]);
  }

  public obterDadosCadastraisPeloCPF(cpf: string, recaptcha: string): Observable<ObterDadosCadastraisPeloCPFResponse | null> {
    return of({
      dadosPessoais: {
        cpf: cpf,
        nomeCompleto: 'João Bosco',
        dataDeNascimento: '01/01/1990',
      },
    });
  }

  public obterDadosDeEnderecoPeloCEP(cep: string, recaptchaToken: string): Observable<ObterDadosDeEnderecoPeloCepResponse | null> {
    return of({
      cep: cep,
      logradouro: 'Rua Centenário',
      numero: '380',
      complemento: '',
      bairro: 'Centro',
      uf: "RJ",
      cidade: "Rio de Janeiro",
    });
  }

  public obterDocumentacaoNecessariaParaElegibilidade() {
    return of<ObterDocumentacaoNecessariaParaElegibilidadeResponse>([
      {
        id: 1,
        icone: 'fa-regular fa-user',
        descricao: 'Cópia do seu CPF',
        itens: [
          {
            id: '1',
            descricao: "Selecione o arquivo",
            descricaoArquivo: "Cópia do CPF",
          },
        ],
      },
      {
        id: 2,
        icone: 'fa-regular fa-id-card',
        descricao: 'Cópia da Identidade',
        itens: [
          {
            id: '2',
            descricao: "Frente",
            descricaoArquivo: "Cópia da identidade - Frente",
          },
          {
            id: '3',
            descricao: "Verso",
            descricaoArquivo: "Cópia da identidade - Verso",
          },
        ],
      },
      {
        id: 3,
        icone: 'fa-solid fa-house',
        descricao: 'Cópia do comprovante de residência',
        itens: [
          {
            id: '4',
            descricao: 'Selecione o arquivo',
            descricaoArquivo: "Cópia do comprovante de residência",
          },
        ],
      },
    ]);
  }

  enviarDocumentacao(payload: EnviarDocumentacaoPayload): Observable<null> {
    return of(null);
  }

  obterPlano(): Observable<ObterPlanoResponse> {
    return of({
      titulo: 'Associação Anual',
      subtitulo: 'Plano Anual',
      valor: 'R$ 85,00/ ano',
      parcelas: 'em até 10x sem juros no cartão',
      descricao: 'Tenha acesso a todas vantagens de um associado CLUBCARD por apenas <b>R$ 85,00 anual</b>. Ao tornar-se um associado, você poderá usufruir de diversos benefícios, como por exemplo: descontos em restaurantes, escolas, cursos e farmácias.',
      aceiteAPropostaDeAssociacao: `
        <h1>Condições de Adesão ao ClubCard</h1>
        <p>
          Solicito a minha inclusão e dos meus dependentes no cadastro da CLUBCARD – Clubcard Benefícios,
          inscrita no CNPJ sob o n° 36.514.725/0001-45. Declaro estar ciente que a CLUBCARD é um clube de benefícios sem fins
          econômicos e que minha contribuição anual visa a manutenção da entidade, bem como possibilita a utilização dos
          benefícios proporcionados pelos convênios celebrados pela CLUBCARD. Sou responsável pela veracidade das declarações
          e informações acima prestadas e declaro que faço parte do Clubcard Benefícios.
        </p>
        <br>
        <p>
          Caso eu venha aderir ao plano de saúde coletivo por adesão através da entidade, solicito que a contribuição associativa
          seja inserida junto com a cobrança do plano solicitado.
          Estou ciente que não tenho direito a voto nem ser votado para qualquer cargo da entidade.
          Concordo com a Contribuição Associativa no valor de R$ 85,00 reais (Oitenta e cinco reais) Anual.
          Cópia do RG, CPF, comprovante de residência e declaração de escolaridade em papel timbrado com carimbo
          contendo CNPJ e razão social da instituição.
        </p>
        <br>
        <p>
          Cada dado solicitado no preenchimento da ficha, foi colhido para cumprimento de obrigação Legal em conformidade
          com a LGPD (artigo 7º da Lei 13.709).
        </p>
      `,
      aceitesExtras: [
        "Concordo com o envio de e-mails marketing e demais comunicações da EBRASIL."
      ],
    });
  }

  realizarPedido(payload: RealizarPedidoDeAssociacaoPayload): Observable<RealizarPedidoDeAssociacaoResponse> {
    return of({
      numeroDoPedido: '001',
      quantidade: 1,
      pedidos: ['CLUBCARD - Clubcard Benefícios'],
      plano: 'Plano anual',
      valorTotal: '85,00',
    });
  }
}
