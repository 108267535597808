<div class="custom-select">
  <div class="select-selected" (click)="toggleOptions()" [ngClass]="{'isSelected': selectedOption}">
    {{ displayName }}

    <i *ngIf="!optionsVisible" class="fa-solid fa-chevron-down"></i>
    <i *ngIf="optionsVisible" class="fa-solid fa-chevron-up"></i>
  </div>

  <div class="select-items" [ngClass]="{'show': optionsVisible}">
    <div *ngFor="let option of options" (click)="selectOption(option)">{{ option.nome }}</div>
  </div>
</div>
