export const environment = {
  production: false,
  mock: false,
  idEntidade: 1,
  recaptcha: {
    v2key: '6LdHMR4qAAAAANAs1oK5cxEZ3GfQxkxQFPYWaLpB',
    v3key: '6Lf1NQkqAAAAAKPEu_75Fml_v7OtC5QWk7m39_ZP',
  },
  api: {
    associaFacil: {
      // host: 'http://localhost:5007',
      host: 'https://elegibilidadebrasil-associafacil-api.hmg.marlin.com.br',
      endpoints: {
        associado: {
          get: {
            getAssociado: '/Associado',
          },
        },
        preCadastro: {
          post: {
            realizarPreCadastro: '/PreCadastro/realizarPreCadastro',
          },
          get: {
            documentacao: '/PreCadastro/obterDocumentacaoNecessariaParaElegibilidade',
            obterDadosCadastraisPeloCpf: '/PreCadastro/obterDadosCadastraisPeloCpf',
            obterDadosCadastraisPeloCep: '/PreCadastro/obterDadosDeEnderecoPeloCep',
            obterPlano: '/PreCadastro/obterPlano',
            obterProfissoes: '/PreCadastro/obterProfissoes',
            obterEstados: '/PreCadastro/obterEstados',
          },
        },
        pagamento: {
          get: {
            obterPedidoPorUid: '/Pagamento/obterPedidoPorUid',
            obterParcelasDoPedido: '/Pagamento/obterParcelasDoPedido',
          },
          post: {
            pagarComCartaoDeCredito: '/Pagamento/pagarComCartaoDeCredito',
            gerarPagamentoPix: '/Pagamento/gerarPagamentoPix',
            statusPagamentoPix: '/Pagamento/statusPagamentoPix',
          },
        },
      }
    }
  }
};
