import { Injectable } from "@angular/core";
import { PagamentoService } from "./pagamento.service";
import { Observable, of } from "rxjs";
import { ObterPedidoPorUidResponse } from "./DTO/response/ObterPedidoPorUidResponse";
import { PagarComCartaoDeCreditoPayload } from "./DTO/request/PagarComCartaoDeCreditoPayload";
import { ResultadoPagamentoResponse, ResultadoPagamentoStatus } from "./DTO/response/PagarComCartaoDeCreditoResponse";
import { ObterParcelasResponse } from "./DTO/response/ObterParcelasResponse";
import * as currency from "currency.js";
import { ObterPedidoPorUidParams } from "./DTO/request/ObterPedidoPorUidParams";
import { GerarPagamentoPixPayload } from "./DTO/request/GerarPagamentoPixPayload";
import { GerarPagamentoPixResponse } from "./DTO/response/GerarPagamentoPixResponse";
import { StatusPagamentoPixResponse } from "./DTO/response/StatusPagamentoPixResponse";
import { StatusPagamentoPixPayload } from "./DTO/request/StatusPagamentoPixPayload";

@Injectable({
  providedIn: 'root'
})
export class PagamentoMockService implements PagamentoService {

  private resultado: ResultadoPagamentoStatus[] = [
    {
      codigo: "00",
      tipo: "Approved or completed successfully",
      retentativa: false,
      mensagem: "APROVADA E COMPLETADA COM SUCESSO",
    },
    {
      codigo: "01",
      tipo: "Refer to card issuer",
      retentativa: false,
      mensagem: "CONTATE A CENTRAL DO SEU CARTAO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "03",
      tipo: "Invalid merchant",
      retentativa: false,
      mensagem: "TRANSACAO NAO PERMITIDA - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "04",
      tipo: "Pick-up",
      retentativa: false,
      mensagem: "CONTATE A CENTRAL DO SEU CARTAO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "05",
      tipo: "Do not honor",
      retentativa: true,
      mensagem: "CONTATE A CENTRAL DO SEU CARTAO",
    },
    {
      codigo: "06",
      tipo: "Error",
      retentativa: true,
      mensagem: "LOJISTA, CONTATE O ADQUIRENTE",
    },
    {
      codigo: "07",
      tipo: "Pick-up special",
      retentativa: false,
      mensagem: "TRANSAÇÃO NÃO PERMITIDA PARA O CARTÃO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "08",
      tipo: "Honour with identification",
      retentativa: true,
      mensagem: "NAO AUTORIZADA - TENTE NOVAMENTE USANDO AUTENTICACAO",
    },
    {
      codigo: "12",
      tipo: "Invalid transaction",
      retentativa: false,
      mensagem: "TRANSACAO INVALIDA - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "13",
      tipo: "Invalid amount",
      retentativa: false,
      mensagem: "VALOR DA TRANSACAO NAO PERMITIDO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "14",
      tipo: "Invalid card number (no such number)",
      retentativa: false,
      mensagem: "VERIFIQUE OS DADOS DO CARTAO",
    },
    {
      codigo: "15",
      tipo: "Não such issuer",
      retentativa: false,
      mensagem: "DADOS DO CARTAO INVALIDO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "19",
      tipo: "Re-enter transaction",
      retentativa: true,
      mensagem: "REFAZER A TRANSACAO",
    },
    {
      codigo: "23",
      tipo: "Unacceptable transaction fee",
      retentativa: false,
      mensagem: "PARCELAMENTO INVALIDO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "30",
      tipo: "Format error",
      retentativa: false,
      mensagem: "ERRO NO CARTÃO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "38",
      tipo: "Allowable PIN tries exceeded",
      retentativa: false,
      mensagem: "QTDADE DE TENTATIVAS EXCEDIDAS - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "39",
      tipo: "Não credit account",
      retentativa: false,
      mensagem: "UTILIZE FUNCAO DEBITO",
    },
    {
      codigo: "40",
      tipo: "Requested function not supported",
      retentativa: false,
      mensagem: "SAQUE NAO DISPONIVEL - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "41",
      tipo: "Lost card",
      retentativa: false,
      mensagem: "TRANSAÇÃO NÃO PERMITIDA - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "42",
      tipo: "Não universal account",
      retentativa: false,
      mensagem: "UTILIZE FUNCAO CREDITO",
    },
    {
      codigo: "43",
      tipo: "Stolen card",
      retentativa: false,
      mensagem: "TRANSAÇÃO NÃO PERMITIDA - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "51",
      tipo: "Nãot suffiecient funds",
      retentativa: false,
      mensagem: "NAO AUTORIZADA",
    },
    {
      codigo: "54",
      tipo: "Expired card",
      retentativa: false,
      mensagem: "VERIFIQUE OS DADOS DO CARTÃO",
    },
    {
      codigo: "55",
      tipo: "Incorrect personal identification number",
      retentativa: true,
      mensagem: "SENHA INVALIDA",
    },
    {
      codigo: "57",
      tipo: "Transaction not permitted to cardholder",
      retentativa: false,
      mensagem: "TRANSACAO NAO PERMITIDA PARA O CARTAO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "58",
      tipo: "Transaction not permitted to terminal",
      retentativa: false,
      mensagem: "TRANSACAO NAO PERMITIDA - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "59",
      tipo: "Suspected fraud",
      retentativa: true,
      mensagem: "CONTATE A CENTRAL DO SEU CARTÃO",
    },
    {
      codigo: "61",
      tipo: "Exceeds withdrawal amount limit",
      retentativa: true,
      mensagem: "VALOR EXCEDIDO. CONTATE A CENTRAL DO SEU CARTAO",
    },
    {
      codigo: "62",
      tipo: "Restricted card",
      retentativa: false,
      mensagem: "CARTAO NAO PERMITE TRANSACAO INTERNACIONAL",
    },
    {
      codigo: "63",
      tipo: "Security violation",
      retentativa: false,
      mensagem: "VERIFIQUE OS DADOS DO CARTÃO",
    },
    {
      codigo: "64",
      tipo: "Original amount incorrect",
      retentativa: false,
      mensagem: "VALOR DA TRANSAÇÃO NÃO PERMITIDO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "65",
      tipo: "Exceeds withdrawal frequency limit",
      retentativa: true,
      mensagem: "QTDADE DE SAQUES EXCEDIDA. CONTATE A CENTRAL DO SEU CARTAO",
    },
    {
      codigo: "74",
      tipo: "Reserved for ISO use / PIN error",
      retentativa: false,
      mensagem: "SENHA INVÁLIDA - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "75",
      tipo: "Allowable number of PIN tries exceeded",
      retentativa: true,
      mensagem: "EXCEDIDAS TENTATIVAS DE SENHA. CONTATE A CENTRAL DO SEU CARTAO",
    },
    {
      codigo: "76",
      tipo: "Reserved for private use / Invalid target account",
      retentativa: false,
      mensagem: "CONTA DESTINO INVALIDA - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "77",
      tipo: "Reserved for private use / Invalid source account",
      retentativa: false,
      mensagem: "CONTA ORIGEM INVALIDA - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "78",
      tipo: "Reserved for private use / New blocked card",
      retentativa: true,
      mensagem: "DESBLOQUEIE O CARTAO",
    },
    {
      codigo: "82",
      tipo: "Reserved for private use / Invalid card",
      retentativa: false,
      mensagem: "ERRO NO CARTAO - NAO TENTE NOVAMENTE",
    },
    {
      codigo: "91",
      tipo: "Issuer or switch is inoperative",
      retentativa: true,
      mensagem: "FALHA DE COMUNICACAO - TENTE MAIS TARDE",
    },
    {
      codigo: "92",
      tipo: "Financial institution or intermediate network facility cannot be found for routing",
      retentativa: false,
      mensagem: "CONTATE A CENTRAL DO SEU CARTÃO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "93",
      tipo: "Transaction cannot be completed. Violation of law",
      retentativa: false,
      mensagem: "TRANSAÇÃO NÃO PERMITIDA PARA O CARTÃO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "94",
      tipo: "Duplicate transmission",
      retentativa: false,
      mensagem: "CONTATE A CENTRAL DO SEU CARTÃO - NÃO TENTE NOVAMENTE",
    },
    {
      codigo: "96",
      tipo: "System malfunction",
      retentativa: true,
      mensagem: "FALHA DE COMUNICACAO - TENTE MAIS TARDE",
    },
    {
      codigo: "99",
      tipo: "Reserved for national use / Amount mismatch",
      retentativa: false,
      mensagem: "VALOR DIFERENTE DA PRE AUTORIZACAO - NAO TENTE NOVAMENTE",
    },
  ];

  public pagarComCartaoDeCredito(payload: PagarComCartaoDeCreditoPayload): Observable<ResultadoPagamentoResponse> {
    if(payload.numeroCartao.replace(/\D/g, '') === "5402208494491782") {
      throw { error: "Não foi possível realizar o pagamento. Tente novamente mais tarde." };
    }

    if(payload.numeroCartao.replace(/\D/g, '') === "5361322411757412") {
      throw { error: "Não foi possível realizar o pagamento." };
    }

    return of({
      status: 'sucesso',
      titulo: 'Pagamento confirmado',
      descricao: 'A confirmação de pagamento será enviada ao seu e-mail cadastrado',
      pedido: '12345678',
      dataDaCompra: '27 de julho',
      itens: ['CLUBCARD - Clubcard Benefícios'],
      valorTotalPago: 'R$ 25,00',
      metodoPagamento: 'Cartão de crédito',
      parcelas: '1x R$ 25,00',
    });
  }

  public obterPedidoPorUid(params: ObterPedidoPorUidParams): Observable<ObterPedidoPorUidResponse> {
    return of({
      fatura: {
        uid: params.uid,
        dataEmissao: "25/04/2024",
        dataVencimento: "30/04/2024",
        itens: ["CLUBCARD - Clubcard Benefícios"],
        valorTotal: "R$ 25,00",
        valorTotalPix: "R$ 22,50",
        descontoPraticadoPix: "Aproveite 10% de desconto",
      }
    });
  }

  public obterParcelasDisponiveis(pedidoUid: string): Observable<ObterParcelasResponse> {
    const valorDoPlano = currency(25.00);
    const jurosMensais = 0.01;
    const parcelamentoMaximo = 10;

    const parcelas: ObterParcelasResponse = [{
      parcelas: 1,
      valor: `R$ ${valorDoPlano.format({ decimal: ',' })}`,
    }];

    for(let i = 2; i <= parcelamentoMaximo; i++) {
      const valorJuros = currency(valorDoPlano.multiply(jurosMensais * i));
      const valorDoPlanoComJuros = currency(valorDoPlano.add(valorJuros));
      const valorDoPlanoComJurosParcelado = valorDoPlanoComJuros.divide(i);

      parcelas.push({
        parcelas: i,
        valor: `R$ ${valorDoPlanoComJurosParcelado.format({ decimal: ',' })}`,
      });
    }

    return of(parcelas);
  }

  public statusPagamentoPix(payload: StatusPagamentoPixPayload): Observable<StatusPagamentoPixResponse> {
    return of<StatusPagamentoPixResponse>({
      pago: true,
      status: "Pago",
      pagamento: {
        status: "Sucesso",
        titulo: "Pagamento confirmado",
        descricao: "A confirmação de pagamento será enviada ao seu e-mail cadastrado",
        pedido: "1234567",
        dataDaCompra: "08/08/2024",
        itens: ["CLUBCARD - Clubcard Benefícios"],
        valorTotalPago: "1x R$ 200,00",
        metodoPagamento: "Pix",
        parcelas: "1x R$ 200,00",
      },
    });
  }

  public gerarPagamentoPix(_: GerarPagamentoPixPayload): Observable<GerarPagamentoPixResponse> {
    return of({
      amount: 2500,
      valorTotal: "R$ 25,00",
      pixCopiaCola: "lorem ipsum dolor amet",
      qrCodeBase64: "base64 do QRCode",
      idPagamento: "id do pagamento",
    });
  }
}
