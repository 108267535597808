<ui-popup [isOpen]="isOpen">
  <div class="yes-or-no-popup">
    <button class="close" (click)="onNo.emit()">
      <i class="fas fa-times"></i>
    </button>

    <h2 class="popup-title">{{title}}</h2>
    <p class="popup-description">{{message}}</p>

    <div class="buttons">
      <button class="button no-button" (click)="onNo.emit()">
        Não
      </button>

      <button class="button yes-button" (click)="onYes.emit()">
        Sim
      </button>
    </div>
  </div>
</ui-popup>