import { Injectable } from '@angular/core';
import { DadosAssociadoService } from './dados-associado.service';
import { GetAssociadoResponse } from './DTO/response/getAssociadoResponse';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DadosAssociadoMockService implements DadosAssociadoService  {
  public getAssociado(_: string): Observable<GetAssociadoResponse> {
    return of({
      nomeCompleto: "Gustavo Carvalho",
      dataDeNascimento: "18/02/1992",
      cpf: "14045913700",
      status: "Ativo",
      email: "gustavo.carvalho@grminternet.com.br",
      telefone: "21912345678",
      cep: "23890001",
      logradouro: "Rodovia BR-465",
      numero: "112",
      complemento: "",
      bairro: "Jardins",
      uf: "RJ",
      cidade: "Seropédica"
    });
  }
}
