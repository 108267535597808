<ui-popup [isOpen]="isOpen">
  <div class="popup-ok">
    <h2 class="popup-title">{{title}}</h2>
    <p class="popup-description">{{message}}</p>

    <div class="buttons">
      <button class="button no-button" (click)="onOk.emit()">
        Ok
      </button>
    </div>
  </div>
</ui-popup>