import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class GoogleRecaptchaService {
  abstract generateTokenV3(): Promise<string>;
}

@Injectable({ providedIn: 'root' })
export class GoogleRecaptchaApiService implements GoogleRecaptchaService {
  public v3Key = environment.recaptcha.v3key;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }

  async generateTokenV3(): Promise<string> {
    return await firstValueFrom(this.recaptchaV3Service.execute(this.v3Key));
  }
}

@Injectable({ providedIn: 'root' })
export class GoogleRecaptchaMockService implements GoogleRecaptchaService {
  generateTokenV3(): Promise<string> {
    return new Promise(resolve => resolve("TOKEN_MOCKED"));
  }
}
