import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetAssociadoResponse } from './DTO/response/getAssociadoResponse';

@Injectable({
  providedIn: 'root'
})
export abstract class DadosAssociadoService {
  abstract getAssociado(id: string): Observable<GetAssociadoResponse>;
}
